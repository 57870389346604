import React from "react";
import { css } from "@emotion/react";

import aboutBg from "../images/about-bg.jpg";

import staff1 from "../images/staffs/1.jpg";
import staff2 from "../images/staffs/2.jpg";
import staff3 from "../images/staffs/3.jpg";
import staff4 from "../images/staffs/4.jpg";
import staff5 from "../images/staffs/5.jpg";
import staff6 from "../images/staffs/6.jpg";
import staff7 from "../images/staffs/7.jpg";
import staff8 from "../images/staffs/8.jpg";
import { GlobalNavigationBar } from "../components/GlobalNavigationBar";
import { RecoilRoot } from "recoil";
import SiteHead from "../head";

const staffs = [
  {
    name: "남궁권",
    designation: "엑셀플레이스 대표",
    twitter: "dionysosng",
    image: staff1,
  },
  {
    name: "예차니즘",
    designation: "인생예차니스트",
    twitter: "yechanism_",
    image: staff2,
  },
  {
    name: "크롱",
    designation: "즐거운 공룡",
    twitter: "csecr24",
    image: staff3,
  },
  {
    name: "슬기찡",
    designation: "마케터 & 디자이너",
    twitter: "seulgi_room",
    image: staff4,
  },
  {
    name: "HADO",
    designation: "전투형 햄찌",
    twitter: "dearmy_zz",
    image: staff5,
  },
  {
    name: "miss petal",
    designation: "Web3 evangelist",
    twitter: "yourmisspetal",
    image: staff6,
  },
  {
    name: "송동훈",
    designation: "개발자",
    twitter: "song_donghoon",
    image: staff7,
  },
  {
    name: "초승달",
    designation: "마법사",
    twitter: "crescent_stdio",
    image: staff8,
  },
];

const pageHeroStyle = css`
  background: url(${aboutBg}) center;
  background-size: cover;

  padding: 390px 0;
  text-align: center;

  @media (max-width: 768px) {
    padding: 168px 0;
  }

  h1 {
    font-size: 3rem;
    margin-bottom: 1.56rem;
  }

  h2 {
    font-size: 2rem;
    font-weight: 100;
  }
`;

const aboutStyle = css`
  background: #222222;
  padding-top: 255px;
  padding-bottom: 323px;

  @media (max-width: 768px) {
    padding-top: 111px;
    padding-bottom: 141px;
  }

  .container {
    max-width: 1200px;
  }

  h3 {
    font-size: 3rem;
    margin-bottom: 3rem;
    text-align: center;

    @media (max-width: 768px) {
      font-size: 1.5rem;
      margin-bottom: 1.25rem;
    }
  }

  p {
    font-size: 1.375rem;
    font-weight: 100;
    margin-bottom: 110px;
    text-align: center;

    @media (max-width: 768px) {
      font-size: 1rem;
      margin-bottom: 54px;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  h4 {
    font-size: 1.875rem;
    margin-top: 215px;
    margin-bottom: 1.25rem;
    text-align: center;

    @media (max-width: 768px) {
      font-size: 1.5rem;
      margin-top: 133px;
      margin-bottom: 14px;
    }
  }

  .staffs {
    display: flex;
    flex-wrap: wrap;

    margin-left: -10px;
    margin-right: -10px;
    list-style: none;
    padding: 0;

    li {
      padding: 0 10px;
      margin-bottom: 20px;
      width: 25%;

      .card {
        overflow: hidden;
        border-radius: 0.5rem;
        background: #404040;

        img {
          width: 100%;
        }

        .card-body {
          padding: 26px 23px;
        }

        .name-desc {
          display: flex;
          align-items: flex-end;
          margin-bottom: 0.5rem;

          .name {
            font-size: 1.25rem;
            margin-right: 10px;
            font-weight: 700;
          }

          .desc {
            font-size: 0.75rem;
            font-weight: 300;
          }
        }

        .twitter {
          font-size: 1rem;
          font-weight: 300;
        }
      }
    }

    @media (max-width: 1060px) {
      li {
        width: 33.33%;
      }
    }

    @media (max-width: 838px) {
      margin-left: -5px;
      margin-right: -5px;

      li {
        padding: 0 5px;
        margin-bottom: 10px;
        width: 50%;

        .card {
          border-radius: 3px;

          .card-body {
            padding: 13px;
          }

          .name-desc {
            display: block;
            margin-bottom: 1.125rem;
          }

          .name {
            font-size: 1rem;
            margin-bottom: 0.25rem;
          }

          .desc {
            font-size: 0.625rem;
          }
        }
      }
    }
  }
`;

const AboutPage = () => {
  return (
    <RecoilRoot>
      <GlobalNavigationBar />
      <div css={pageHeroStyle}>
        <h1>ABOUT</h1>
        <h2>EXCELCON준비단</h2>
      </div>
      <div css={aboutStyle}>
        <div className="container">
          <h3>EXCELCON준비단</h3>
          <p>
            EXCELCON은 엑셀플레이스와 EXCELCON준비단이 함께 만드는 행사입니다.
          </p>
          <ul className="staffs">
            {staffs.map((staff, index) => (
              <li>
                <div className="card">
                  <img src={staff.image} alt="남궁권 사진" />
                  <div className="card-body">
                    <div className="name-desc">
                      <div className="name">{staff.name}</div>
                      <div className="desc">{staff.designation}</div>
                    </div>
                    <div className="twitter">
                      <a href={`https://twitter.com/${staff.twitter}`}>
                        @{staff.twitter}
                      </a>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>

          <h4>E-mail</h4>
          <p>excelcon.day@gmail.com</p>
        </div>
      </div>
    </RecoilRoot>
  );
};
export const Head = () => (
  <SiteHead meta={{ title: "EXCELCON 준비단 | EXCELCON" }} />
);

export default AboutPage;
